<template>
<div class="p-grid p-fluid home">
    <div class="home" style="width: 100%;">
        <div class="templates">
            <h4><strong>{{$t('Contact Mens')}}</strong></h4>
            <div class="p-grid">
                <div class="p-col-12 p-md-4">
                    <h5>{{$t('Mail us')}}</h5>
                    <p><i class="pi pi-envelope"></i> sales@sabeeinsurance.com</p>
                </div>
                <div class="p-col-12 p-md-4">
                    <h5>{{$t('Address')}}</h5>
                    <p>
                        <i class="bi bi-geo-alt"></i> Piscaderabaai Z/N, World Trade Center Curaçao <br>
                        <i class="bi bi-geo-alt"></i> Kaya Grandy N8 Kadelick BONAIRE. Z/n 
                    </p>
                </div>
                <div class="p-col-12 p-md-4">
                   <h5>{{$t('Call Us')}}</h5>
                    <p>
                        <i class="bi bi-telephone"></i> +5999 463 6251 <br>
                        <i class="bi bi-telephone"></i> +5999 463 6249
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1950.453539478653!2d-68.96558652909523!3d12.118510030481527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e84831ac9b937f7%3A0x1a263e8ca50cd6e2!2sWorld%20Trade%20Center%20Cura%C3%A7ao!5e0!3m2!1ses!2sve!4v1621129022837!5m2!1ses!2sve" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
                <div class="p-col-12 p-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1950.453539478653!2d-68.96558652909523!3d12.118510030481527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e84831ac9b937f7%3A0x1a263e8ca50cd6e2!2sWorld%20Trade%20Center%20Cura%C3%A7ao!5e0!3m2!1ses!2sve!4v1621129022837!5m2!1ses!2sve" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
        </div>

        <div class="prosupport" style="background-image: url('./images/prefondo3.png');">
            <div class="p-grid"  >
                <div class="p-col-12 p-md-3">
                    <a href="mailto:sales@sabeeinsurance.com" style=" color: #727376;">
                        <i class="pi pi-calendar"></i> {{$t('Concertar cita')}} <br>
                    </a>
                </div>
                 <div class="p-col-12 p-md-3">
                    <a href="mailto:sales@sabeeinsurance.com" style=" color: #727376;">
                        <i class="pi pi-calendar"></i> {{$t('Servicio al Cliente')}} <br>
                    </a>
                </div>
                 <div class="p-col-12 p-md-3">
                    <a href="mailto:sales@sabeeinsurance.com" style=" color: #727376;">
                        <i class="pi pi-calendar"></i> {{$t('Agente de contacto')}} <br>
                    </a>
                </div>
            </div>
        </div>


	</div>


</div>
</template>

<script>

export default {
	components: {
    },
	data() {
		return {
            dark: false,
            images: [ 
				{ 
					"itemImageSrc": "images/banner1.jpg", 
					"thumbnailImageSrc": "images/banner1.jpg", 
					"alt": "", 
					"title": "Banner 1" 
				}, 
				{ 
					"itemImageSrc": "images/banner2.jpg", 
					"thumbnailImageSrc": "images/banner2.jpg", 
					"alt": "", 
					"title": "Banner 2" 
				}, 
				{ 
					"itemImageSrc": "images/banner3.jpg", 
					"thumbnailImageSrc": "images/banner3.jpg", 
					"alt": "", 
					"title": "Banner 2" 
				}
			],
			responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
			]
			
		}
	},
	productService: null,
	eventService: null,
	created() {
	},
	mounted() {
	},
	methods: {
	},
	computed: {
        introductionClass() {
            return ['introduction', {'introduction-dark': true}];
        }
    }

}
</script>
